@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "vendor/sweetalert.theme";

html {
	font-size: 16px;
	scrollbar-color: #2bb24c;
	font-family: "Poppins", sans-serif;
	background-color: #1b1b1b;
	color: #ffffff;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 100px;
}

::-webkit-scrollbar-thumb {
	background-color: #ababab98;
	border-radius: 100px;
	opacity: 0.5;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #c9c9c9;
	border-radius: 100px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
	display: none;
}

.tip-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow-x: scroll;
	width: 100%;
	// max-width: 100vw;
	margin: 20px -20px;
	scroll-behavior: smooth;
}

.empty-price-area {
	min-width: 100px;
	margin-left: 5px;
	margin-right: 5px;
}

.price-container {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	padding: 5px;
	border: 1px solid white;
	border-radius: 10px;
	min-width: 100px;
	margin-left: 5px;
	margin-right: 5px;
	cursor: pointer;
	transition: all 0.2s;
}

.price-container.selected {
	background: white;
}

.price {
	font-size: 38px;
	color: white;
	margin: 0;
	font-weight: 600;
}

.currency {
	font-size: 12px;
	margin: 0;
}

.price-container.selected .price {
	color: #2ab24c;
}

.price-container.selected .currency {
	color: #2ab24c;
}

.scan_line {
	animation: move_up_down 2.5s linear infinite;
}

@keyframes move_up_down {
	0%,
	100% {
		top: calc(0% + 10px);
	}
	50% {
		top: calc(100% - 10px);
	}
}
